"use client";

import { Fragment, useState } from "react";
import { useRouter } from "next/navigation";
import { getIcon } from "@litonarefin/utils/icons/index";

export const BlogSearch = ({ type = "" }) => {
    const [searchText, setSearchText] = useState("");

    const router = useRouter();

    const handleKeyDown = (e) => {
        if (!searchText || searchText.length < 3) return;

        if (e.key === "Enter") {
            router.push(`/search?search=${searchText}`);
        }
    };

    const handleSearch = () => {
        if (!searchText || searchText.length < 3) return;
        router.push(`/search?search=${searchText}`);
    };

    return (
        <Fragment>
            {type === "side-post" ? (
                <div className="jlt-relative jlt-mt-4">
                    <input
                        type="text"
                        placeholder="Search here..."
                        className="jlt-py-[14px] jlt-pl-6 jlt-pr-[54px] jlt-border jlt-rounded-full focus:jlt-outline-none jlt-text-sm jlt-text-[#475467] jlt-w-full"
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                    <span className="jlt-absolute jlt-right-6 jlt-top-4 jlt-cursor-pointer" onClick={handleSearch}>
                        {getIcon("search")}
                    </span>
                </div>
            ) : (
                <div className="jlt-flex jlt-justify-between jlt-items-center jlt-gap-2 jlt-rounded-[30px] jlt-bg-[#FFFFFF] jlt-py-2 jlt-px-4 shadow-custom">
                    {getIcon("search")}
                    <input
                        type="text"
                        placeholder="Search..."
                        className="jlt-w-[60px] focus:jlt-w-[200px] jlt-transition-all jlt-duration-200 jlt-text-sm jlt-text-[#475467] jlt-font-bold jlt-outline-0"
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e)}
                    />
                </div>
            )}
        </Fragment>
    );
};
