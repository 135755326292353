import React, { useState, useEffect } from "react";
import Loader from "../Loader";
import axios from "axios";
import { getIcon } from "@litonarefin/utils/icons";
import { getStyledIcon } from "@litonarefin/utils/getStyledIcon";
import { handleToastr } from "@litonarefin/utils/handleToastr";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";

export const CommentForm = ({
    reply,
    setReply,
    post_id,
    comment_id,
    fetchComments,
    depth,
}) => {
    const [userLogin, setUserLogin] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [comments, setComments] = useState({
        post: post_id,
        content: "",
        author_email: "",
        author_name: "",
    });

    const siteSource = getSourceSite();

    const handleChange = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        setComments({ ...comments, [key]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            comments?.content == "" ||
            comments?.author_email == "" ||
            comments?.author_name == ""
        ) {
            return;
        }
        setSpinner(true);

        let { data } = await axios.post("/api/comments", {
            ...comments,
            parent: reply || 0,
        });
        if (data?.success) {
            fetchComments(comments);
            handleToastr("Comment created Successfully!");
            setSpinner(false);
            setComments({
                post: post_id,
                content: "",
                author_email: "",
                author_name: "",
            });
            setReply(null);
        }
    };

    useEffect(() => {
        setComments({
            ...comments,
            author_email: localStorage.getItem(`${siteSource}AuthUserEmail`),
            author_name: localStorage.getItem(
                `${siteSource}AuthUserDisplayName`
            ),
        });
        setUserLogin(localStorage.getItem(`${siteSource}AuthUserId`) || 0);
    }, []);

    return (
        <div style={{ marginLeft: `${depth * 50}px` }}>
            <div className="jlt-flex jlt-items-center jlt-justify-between">
                <div>
                    <h5 className="jlt-text-[#1D2939]">Leave a Comment</h5>
                    <p className="jlt-mt-2">
                        Your email address will not be published
                    </p>
                </div>
                {reply != null && (
                    <button
                        onClick={() => setReply(null)}
                        className="jlt-text-[#D92D20] jlt-flex jlt-items-center jlt-gap-2 jlt-text-xs jlt-font-semibold jlt-py-2 jlt-px-4 jlt-bg-[#FECDCA] jlt-rounded">
                        <span>Cancel Reply</span>
                        {getStyledIcon(
                            getIcon("curvedArrow"),
                            "#D92D20",
                            "stroke"
                        )}
                    </button>
                )}
            </div>

            <div className="jlt-mt-12 jlt-p-8 jlt-bg-[#ffff] jlt-rounded jlt-border jlt-border-[#F2F4F7]">
                <form>
                    {userLogin == 0 && (
                        <>
                            <div>
                                <label
                                    htmlFor="author_name"
                                    className="jlt-text-[#475467] jlt-block jlt-font-medium">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="author_name"
                                    name="author_name"
                                    value={comments?.author_name || ""}
                                    onChange={handleChange}
                                    className="jlt-w-full jlt-h-12 jlt-mt-2 jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-outline-0 jlt-px-5 jlt-text-base jlt-text-[#16213E] jlt-font-normal"
                                />
                            </div>
                            <div className="jlt-mt-6">
                                <label
                                    htmlFor="author_email"
                                    className="jlt-text-[#475467] jlt-block jlt-font-medium">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="author_email"
                                    name="author_email"
                                    value={comments?.author_email || ""}
                                    onChange={handleChange}
                                    className="jlt-w-full jlt-h-12 jlt-mt-2 jlt-bg-[#FCFDFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-outline-0 jlt-px-5 jlt-text-base jlt-text-[#16213E] jlt-font-normal"
                                />
                            </div>
                        </>
                    )}
                    <div className={`${userLogin == 0 ? "jlt-mt-6" : ""}`}>
                        <label
                            htmlFor="content"
                            className="jlt-text-[#475467] jlt-block jlt-font-medium jlt-leading-6">
                            Comment
                        </label>

                        <textarea
                            id="content"
                            name="content"
                            value={comments?.content || ""}
                            onChange={handleChange}
                            className="jlt-w-full jlt-mt-2 jlt-h-[128px] jlt-bg-[#FAFCFF] jlt-border jlt-border-[#F2F4F7] jlt-outline-0 jlt-p-4 jlt-text-[#16213E] jlt-rounded"
                        />
                    </div>
                    <div className="submit-area jlt-flex jlt-gap-x-25 jlt-mt-8">
                        <input
                            disabled={spinner}
                            type="submit"
                            value="Post Comment"
                            onClick={handleSubmit}
                            className="jlt-py-[10px] jlt-px-4 jlt-bg-[--primary] jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-full jlt-text-sm jlt-text-[#FFFFFF] jlt-font-semibold jlt-cursor-pointer"
                        />
                        {spinner && (
                            <div className="action-loader">
                                <Loader />
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};
