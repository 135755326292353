"use client";

import styled from "styled-components";

const BlogTag = ({ children }) => {
    return <Tag>{children}</Tag>;
};

export default BlogTag;

const Tag = styled.div.attrs((props) => ({
    className:
        "jlt-py-[2px] jlt-px-[10px] jlt-rounded-full jlt-text-sm jlt-font-semibold  jlt-leading-5 odd:jlt-bg-[#E5EEFF] odd:jlt-text-[#2C67DB] even:jlt-bg-[#FFE4E8] even:jlt-text-[#C01048]",
}))`
    &:nth-of-type(3n + 0) {
        background: #ebe9fe;
        color: #5925dc;
    }
    &:nth-of-type(4n + 0) {
        background: #d1fadf;
        color: #027a48;
    }
    &:nth-of-type(5n + 0) {
        background: #fef0c7;
        color: #b54708;
    }
`;
