"use client";

import axios from "axios";
import { useEffect } from "react";

function UpdatePopularPost({ postId }) {
    useEffect(() => {
        const updatePopularPostMeta = async (post_id) => {
            let { data } = await axios.post(`/api/popular-posts`, {
                params: { post_id: post_id },
            });
        };

        if (postId) {
            updatePopularPostMeta(postId);
        }
    }, []);

    return null;
}

export default UpdatePopularPost;
