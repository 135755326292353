import { useState } from "react";
import { CommentForm } from "./CommentForm";
import _ from "lodash";
import { convertTime } from "@litonarefin/utils/convertTime";
import { getIcon } from "@litonarefin/utils/icons/index";

const SingleComment = ({
    comment,
    reply,
    setReply,
    post_id,
    fetchComments,
}) => {
    let depth = comment?.depth <= 2 ? comment?.depth : 2;
    return (
        <>
            <div
                className={`jlt-border jlt-border-[#F2F4F7] jlt-bg-white jlt-rounded jlt-p-8`}
                style={{ marginLeft: `${depth * 50}px` }}>
                <div className="jlt-flex jlt-items-center jlt-justify-between">
                    <div className="jlt-flex jlt-gap-4">
                        <div className="jlt-w-[48px] jlt-h-[48px] jlt-bg-[#D9D9D9] jlt-rounded-full jlt-overflow-hidden">
                            <img
                                src={comment?.author_avatar_urls?.["48"]}
                                alt={comment?.author_name}
                                width={48}
                                height={48}
                            />
                        </div>
                        <div>
                            <h6 className="jlt-text-[#1D2939] jlt-font-bold">
                                {_.startCase(
                                    comment?.author_name.toLowerCase()
                                )}
                            </h6>
                            <span className="jlt-text-sm jlt-text-[#98A2B3] jlt-font-normal">
                                {convertTime(comment?.date)}
                            </span>
                        </div>
                    </div>
                    <button
                        onClick={() => setReply(comment?.id)}
                        className="jlt-text-[--primary] jlt-flex jlt-items-center jlt-gap-2 jlt-text-xs jlt-font-semibold jlt-py-2 jlt-px-4 jlt-bg-[#F5F8FF] jlt-rounded">
                        <span>Reply</span>
                        {getIcon("curvedArrow")}
                    </button>
                </div>
                <div
                    className="jlt-mt-6"
                    dangerouslySetInnerHTML={{
                        __html: comment?.content?.rendered,
                    }}
                />

                {comment?.status == "hold" && (
                    <span className="jlt-italic">
                        Your comment is awaiting moderation.
                    </span>
                )}
            </div>
            {reply == comment?.id && (
                <CommentForm
                    reply={reply}
                    setReply={setReply}
                    post_id={post_id}
                    comment_id={comment?.id}
                    fetchComments={fetchComments}
                    depth={depth}
                />
            )}
            {comment?.children?.length > 0 &&
                comment?.children.map((itm, i) => (
                    <SingleComment
                        key={i}
                        comment={itm}
                        reply={reply}
                        setReply={setReply}
                        post_id={post_id}
                        fetchComments={fetchComments}
                        depth={depth}
                    />
                ))}
        </>
    );
};

export const CommentsList = ({
    allComments,
    totalComments,
    reply,
    setReply,
    post_id,
    fetchComments,
}) => {
    const [hide, setHide] = useState(true);

    return (
        <>
            <div className="jlt-flex jlt-items-center jlt-justify-between jlt-mb-12">
                <h5 className="jlt-text-[#1D2939]">
                    {totalComments?.length || allComments?.length} Comments
                </h5>
                <button
                    onClick={() => setHide(!hide)}
                    className="jlt-py-1 jlt-px-3 jlt-bg-[#F2F4F7] jlt-rounded-full jlt-font-semibold jlt-text-[#344054] jlt-leading-6">
                    {hide ? "Hide" : "Show"} Comments
                </button>
            </div>
            {hide ? (
                <div className="jlt-flex jlt-flex-col jlt-gap-y-8 jlt-mt-12 jlt-mb-12">
                    {(totalComments || allComments)?.length > 0 &&
                        (totalComments || allComments).map((item, i) => (
                            <SingleComment
                                key={i}
                                comment={item}
                                reply={reply}
                                setReply={setReply}
                                post_id={post_id}
                                fetchComments={fetchComments}
                            />
                        ))}
                </div>
            ) : null}
        </>
    );
};
