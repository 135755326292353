"use client";

import { getIcon } from "@litonarefin/utils/icons";
import React, { useState, useEffect } from "react";

const TableOfContent = () => {
    const [activeContent, setActiveContent] = useState(false);
    const [headings, setHeadings] = useState([]);

    useEffect(() => {
        const elements = Array.from(document.querySelectorAll(".jlt-blog-content h2")).map(
            (elem, i) => {
                if (!elem.getAttribute("id")) {
                    elem.setAttribute("id", `heading_${++i}`);
                }
                return {
                    id: elem.id,
                    text: elem.innerText,
                };
            }
        );
        setHeadings(elements);

        //Add Body Class
        let reload = false;
        let timeOut;

        const body = document.body;
        body.classList.add("jlt-table-of-content");

        timeOut = setTimeout(() => {
            reload = true;
        }, 1);

        return () => {
            if (reload) {
                clearTimeout(timeOut);
                body.classList.remove("jlt-table-of-content");
            }
        };
    }, []);

    return (
        <div
            className={`jlt-fixed jlt-top-[130px] jlt-left-auto jlt-hidden sm:jlt-block ${
                activeContent ? "jlt-right-[50px]" : "jlt-right-[-250px]"
            } jlt-w-[250px] jlt-z-[999999999]`}
            style={{ transition: "all 0.4s" }}
        >
            <div className="jlt-relative jlt-flex jlt-items-center jlt-h-[300px]">
                <button
                    type="button"
                    className={`jlt-flex jlt-items-center jlt-absolute jlt-top-auto ${
                        activeContent ? "jlt-left-[-26px]" : "jlt-left-[-68px]"
                    }`}
                    onClick={() => setActiveContent(!activeContent)}
                    style={{ transition: "all 0.3s" }}
                >
                    <div className="jlt-flex jlt-items-center jlt-justify-center jlt-w-7 jlt-h-8 jlt-bg-[#f32f5e] jlt-rounded-l-md">
                        <span
                            className={`${
                                activeContent ? "jlt-rotate-[180deg]" : "jlt-rotate-[0deg]"
                            }`}
                            style={{ transition: "all 0.3s" }}
                        >
                            {getIcon("leftArrow")}
                        </span>
                    </div>
                    <div className="jlt-absolute jlt-top-auto jlt-left-[-24px] !jlt-block jlt-rotate-[-90deg] jlt-origin-top-[-50%]">
                        <p className="jlt-px-[12px] jlt-py-[10px] jlt-bg-[#f32f5e] jlt-text-white jlt-text-[14px] jlt-font-semibold jlt-rounded-t-md jlt-w-max jlt-select-none jlt-tracking-[0.5px]">
                            Table Of Content
                        </p>
                    </div>
                </button>
                <div className="jlt-border jlt-bg-[#fff] jlt-w-full jlt-shadow-md jlt-rounded-l-md jlt-py-5 jlt-px-6 jlt-z-50">
                    <h4 className="jlt-text-[18px] jlt-font-bold jlt-text-[#000] jlt-leading-5 jlt-mb-4">
                        Table of Contents
                    </h4>
                    <ul className="jlt-list-decimal jlt-list-inside jlt-overflow-x-hidden jlt-overflow-y-auto jlt-h-[300px]">
                        {headings.map((heading) => (
                            <li
                                key={heading.id}
                                className="jlt-pl-[15px] jlt-mb-[15px] jlt-pr-2 jlt-text-[13px] jlt-font-medium jlt-leading-[20px] jlt-text-[#717171]"
                            >
                                <a
                                    href={`#${heading.id}`}
                                    className="jlt-select-none"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        document.querySelector(`#${heading.id}`).scrollIntoView({
                                            behavior: "smooth",
                                        });
                                    }}
                                >
                                    {heading.text}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TableOfContent;
